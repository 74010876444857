<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('log_sms.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('log_sms.head_list') }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <span>
              <date-picker-input :date="dateFrom" :label="$t('log_sms.date_from')" @setValue="dateFrom = $event" />
            </span>
            <span>
              <date-picker-input :date="dateTo" :label="$t('log_sms.date_to')" @setValue="dateTo = $event" />
            </span>
            <v-btn color="primary" class="" @click="searchDate"> {{ $t('search') }} </v-btn>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5">{{ $t('search') }}:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="logs"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage" :loading="loading"
            :page.sync="pagination.page" :search="search" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }">
            <template v-slot:[`item.created_at`]="{ item }">
              <span>{{ formatDate(item.created_at) }}</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <!-- <v-btn color="warning" class="white--text text-capitalize">
                รายละเอียด
              </v-btn>
              <v-card>
                  <v-card-text>
                      {{ item.response }}
                  </v-card-text>
              </v-card> -->
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="warning" dark v-bind="attrs" v-on="on"> {{ $t('detail') }} </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>{{ item.response }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      menu: false,
      showDetail: false,
      toggle_exclusive: 0,
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      searchData: null,
      headers: [
        {
          text: '#',
          value: 'id',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t('log_sms.header1'),
          value: 'phone',
          width: '100px',
          sortable: false,
        },

        {
          text: this.$t('log_sms.header2'),
          value: 'ip',
          sortable: false,
        },
        {
          text: this.$t('log_sms.header3'),
          value: 'key',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_sms.header4'),
          value: 'created_at',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_sms.header5'),
          value: 'action',
          width: '300px',
          align: 'center',
          sortable: false,
        },
      ],
      logs: [],
      search: '',
      loading: false,
      dummy: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
    }
  },
  async created() {
    this.addLogPage()
    try {
      // const result = await this.getLogSMSData(`page=1&rows=25`)
      // this.pagination.totalItems = result.count
      // this.logs = result.data
    } catch (e) { }
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        // this.loading = true
        // let result = []
        // result = this.searchData ?
        //   await this.getLogSMSData(`page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}`):
        //   await this.getLogSMSData(`page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`)

        // this.logs = result.data
        // this.pagination.totalItems = result.count
        // this.loading = false
        if (cont && !this.dummy) {
          this.loading = true
          let result = []
          result = this.searchData ?
            await this.getLogSMSData(`page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}`) :
            await this.getLogSMSData(`page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`)

          this.logs = result.data
          this.pagination.totalItems = result.count
          this.loading = false
        }
        if (cont && this.dummy) {
          this.loading = true
          let result = []
          // if(value.itemsPerPage !== -1) {
          result = this.searchData ?
            await this.getLogSMSData(`page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}`) :
            await this.getLogSMSData(`page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`)
          this.logs = result.data
          this.pagination.totalItems = result.count
          this.loading = false
        }
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            name: 'Logs SMS',
            url: window.location.href,
            detail: 'รายการ Logs SMS',
            admin: userSystem.name ? userSystem.name : '',
          }
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    showMemberDetail(value) {
      this.currentMemberDetail = value
      this.showDetail = true
    },
    async searchDate() {
      this.loading = true
      this.dummy = false;
      const params = this.searchData ?
        `page=1&rows=${this.pagination.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}` :
        `page=1&rows=${this.pagination.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`
      const result = await this.getLogSMSData(params)
      this.pagination.totalItems = result.count
      this.logs = result.data
      this.loading = false
      this.pagination.page = 1
    },
    async findTransaction() {
      this.loading = true
      const params = this.searchData ?
        `page=1&rows=${this.pagination.rowsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}` :
        `page=1&rows=${this.pagination.rowsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`
      const result = await this.getLogSMSData(params)
      this.pagination.totalItems = result.count
      this.pagination.page = 1
      this.logs = result.data
      this.loading = false
    },
    async getLogSMSData(params) {
      try {
        return await this.$store.dispatch('getLogSMS', params)
      } catch (e) { }
    },
    async searchKeyword(value) {
      this.dummy = true;
      this.searchData = value
      this.loading = true
      let result = {}
      if (value) {
        result = await this.getLogSMSData(`page=1&rows=${this.pagination.rowsPerPage}&search=${value}`)
      } else {
        result = await this.getLogSMSData(`page=1&rows=${this.pagination.rowsPerPage}`)
      }
      this.pagination.totalItems = result.count
      this.logs = result.data
      this.pagination.page = 1
      this.loading = false
    },
    formatDate(date) {
      return moment(date).format('YY-MM-DD HH:mm')
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
  },
}
</script>
